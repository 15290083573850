<template>
  <div class="main-container">
    <h5 class="pageTitle" v-html="pageTitle"></h5>
    <mdb-container class="search-container">
      <mdb-row>
        <mdb-date-picker-2 far :option="options" v-model="chooseDate" outline @getValue="selectDate" disableOk disableCancel/>
        <!-- <mdb-col>
          <input type="text" v-model="search" placeholder="검색할 Drone ID를 입력하세요." v-on:keypress.enter.prevent="searchButton"/>
        </mdb-col> -->
          <mdb-col>
            <div class="dropdown-wrapper">
                <input type="text" v-model="search" placeholder="검색할 디바이스ID를 입력하세요." @focus="showDropdown = true
                 filterDevices()"  @blur="hideDropdown" @input="filterDevices" />
                <ul v-if="showDropdown && filteredDevices.length" class="dropdown-list">
                    <li v-for="device in filteredDevices" :key="device.id" @mousedown="selectDevice(device.id)">
                     {{ device.id }}
                    </li>
                </ul>
            </div>
          </mdb-col>
        <button class="custom-btn-box btn-search" @click="searchButton">검색</button>
      </mdb-row>
    </mdb-container>
    
    <table >
      <thead>
        <tr>
          <th><b>No</b></th>
          <th><b>비행 시작시간</b></th>
          <th><b>비행 종료시간</b></th>
          <th><b>사용자명</b></th>
          <th><b>디바이스ID</b></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="paginatedData.length === 0">            
            <td colspan="5">데이터가 없습니다.</td>
        </tr>
        <tr v-for="(log,index ) in paginatedData" :key="index" @click="handleRowClick(log.startDate, log.endDate, log.deviceId, log.isFlying)"
            :class="{'alt-row': index % 2 === 1}">
          <td>{{ (currentPage - 1) * perPage + index + 1 }}</td>
          <td>{{ log.startDate }}</td>
          <td :class="{ blinking: log.isFlying }">{{ log.isFlying ?'비행 중...': log.endDate }}</td>
          <td>{{ log.userName }}</td>
          <td>{{ log.deviceId }}</td>
        </tr>
      </tbody>
    </table>
    
    <div class="pagination">
      <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
      <span>Page {{ currentPage }} of {{ pageCount }}</span>
      <button class="pagination-button" @click="nextPage" :disabled="currentPage === pageCount">Next</button>
    </div>
  
  </div>
</template>

<script>
import { mdbCol, mdbContainer,  mdbRow, mdbDatePicker2} from "mdbvue";
//import axios from 'axios';
import {getDate } from "@/lib/prettyDate";
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    mdbContainer,   
    mdbRow,
    mdbCol,
    mdbDatePicker2,
  },
  data() {
    return {
        pageTitle: '드론 로그 목록',
        //token: this.$store.state.token.access_token, 
        //logs: [],
        //selectedDate: new Date().toISOString(),
        options:{
            placeholder: '',
            label: "날짜 선택",
            buttons: {
                ok: '확인',
                cancel: '취소',
                clear: '초기화'
            }
        },
        chooseDate:'',
        search:'',
        //currentPage: 1,
        perPage: 10,               
        filteredDevices: [],
        showDropdown: false,
        initialized: false, 
    };

  },
  computed: {
    ...mapState(['logs', 'selectedDate', 'currentPage','deviceId','devices']),

    paginatedData() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      return this.logs.slice(start, end);
    },
    pageCount() {
      return Math.ceil(this.logs.length / this.perPage);
    }
  },
  methods: {
    ...mapMutations(['setSelectedDate', 'setCurrentPage', 'setLogs', 'setdeviceId','setDevices']),
    ...mapActions(['fetchLogs']),

    init() {
      if (!this.initialized) {
        this.getToday();
        this.fetchLogs({ deviceId: this.search.trim(), date: this.selectedDate });
        this.initialized = true;
      }
    },
    handleRowClick(startDate, endDate, deviceId, isFlying) {  
      if (isFlying)
      {
        endDate = new Date();
      }
      console.log('DroneList.vue =>'+'startDate:', startDate, 'endDate:', endDate, 'deviceId:', deviceId, 'isFlying:', isFlying);
      this.$router.push({ name: 'DroneDetail', params: { startDate, endDate, deviceId, isFlying } });
    },

    filterDevices() {
      if (this.search.trim() === '') {
        this.filteredDevices = this.devices;
      } else {
        this.filteredDevices = this.devices.filter(device => 
          device.id.toLowerCase().includes(this.search.toLowerCase())
        );
      }
    },
    selectDevice(deviceId) {
      this.search = deviceId;
      this.setdeviceId(deviceId);
      this.showDropdown = false;
    },
    hideDropdown() {
      setTimeout(() => {
        this.showDropdown = false;
      }, 200); // 블러 이벤트 후 드롭다운을 숨기기 위해 약간의 지연을 줍니다.
    },
    
    getToday() {
      const self = this;
      if (self.selectedDate !=='')
         self.chooseDate = self.selectedDate;
      else
      {
        const today = getDate(new Date());
        this.setSelectedDate(today); // Vuex의 상태인 selectedDate 초기화
        this.chooseDate = today; // mdb-date-picker-2의 선택된 날짜 초기화
      }  
        
      //console.log('self.chooseDate:'+self.chooseDate)
    },
    
    prevPage() {
      if (this.currentPage > 1) {
        this.setCurrentPage(this.currentPage - 1);
        this.fetchLogs();
      }
    },
    nextPage() {
      if (this.currentPage < this.pageCount) {       
        this.setCurrentPage(this.currentPage + 1);
        this.fetchLogs();
      }
    },
    searchButton() {
      
      this.setCurrentPage(1);
      this.setdeviceId(this.search.trim());     
      this.fetchLogs({ deviceId: this.search.trim(), date: this.selectedDate });
    },

    changePage(newPage) {    
      this.setCurrentPage(newPage);
    },
    
    selectDate(event) {
     
      this.setSelectedDate(event);
      this.setCurrentPage(1);     
      this.setdeviceId(''); 
      this.fetchLogs({ deviceId: this.search.trim(), date: this.selectedDate });
      
    },
   
  },

  watch: {
    selectedDate() {      
      this.fetchLogs({ deviceId: this.search.trim(), date: this.selectedDate });
    }
  },
  mounted() {
      this.init();
  }

}
</script>

<style scoped>
.blinking {
  color: red;  
  animation: blinkingText 1.5s infinite;
}

@keyframes blinkingText {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
  .dropdown-list {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 95%;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    z-index: 10;
    list-style-type: none; /* ul의 글머리 제거 */
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  .dropdown-list li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .dropdown-list li:hover {
    background-color: #f2f2f2;
  }

input {
  margin-top: 23px;
  height: 38px;
  border-radius: 3px;
  width: 100%;
  padding: 8px;
  border: 1px solid rgba(130, 128, 128, 0.26);
}
.btn-search {
  margin-top: 23px; 
  background-color: #e6f2ff;
  padding: 11px 12px;
  font-size: 14px;
  border: 0;
  width: 67px;
  height: 38px;
  line-height: 1rem;
}
.main-container {
  padding: 15px;
}
.pageTitle {
  font-weight: bold;
}
.search-container {
  padding: 25px;
}

table {
  width: 80%;
  height: 50px;
  border-collapse: collapse;
  margin: auto;  
  text-align:center;  
}

th, td {
  border: 1px solid #cbc8c8;
  padding: 8px;
  text-align:center;
}

th {
  background-color: #e3e4eb;
  text-align: center;
  height: 55px;  
}

tr{
    height: 50px;
    cursor: pointer;
}


.alt-row {
  background-color: #f9f9f9; 
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #4CAF50; /* 배경색 */
  color: rgb(251, 250, 251); /* 글자색 */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
}

.pagination-button:hover {
  background-color: #307733; /* 호버 시 배경색 변화 */
}

.pagination-button:disabled {
  background-color: #cccccc; /* 비활성화 시 배경색 */
  color: #666666; /* 비활성화 시 글자색 */
  cursor: not-allowed;
}
.pagination span {
  margin: 0 10px;
  line-height: 1.5;
} 

/* .pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  color: blue
}
*/


tr:hover {
  background-color: #e7e3e3;  
}

</style>

